export const environment = {
  version: 'develop',
  buildDate: '202412201009',
  environment: 'develop',
  release: 'bbd7bd008716cfdd17cc5708ed80f1d38943ff9e',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://develop.api.catch-talents.dev',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
